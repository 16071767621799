<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <div class="mb-6">
        <h2 class="font-semibold text-blue-900 text-2xl">
          Funds Reversal
        </h2>
      </div>
      <div class="bg-blue-100 p-5 my-5">
        <p>
          Please note that all downloads are in .txt formats. You will need to
          change it to .csv format after downloading it. Merci.
        </p>
      </div>

      <div class=" flex justify-end items-end">
        <button
          class=" bg-blue-600 py-3 px-6 rounded-md  text-white"
          @click="downloadCSV"
        >
          Download CSV
        </button>
      </div>
      <div class="flex flex-row mt-5">
        <div class="float-right">
          <input
            type="text"
            class="inline-block  px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
            name=""
            placeholder="Search business name or email address"
            id=""
            v-model="search"
          />
        </div>
      </div>
      <div class="flex flex-col">
        <div
          class="flex justify-between items-center m-3 flex-grow-0 flex-shrink"
        >
          <div class="">
            <div class="flex time-filter">
              <span class="mr-4">From: </span>
              <input
                type="date"
                class="inline-block border-2 px-2 rounded-sm mr-2"
                name=""
                id=""
                v-model="dateData.start_date"
              />
              <input
                type="time"
                class="inline-block border-2 px-2 rounded-sm mr-2"
                name=""
                id=""
                v-model="dateData.startTime"
              />
            </div>
            <div class="flex time-filter mt-2">
              <span class="mr-9">To: </span>
              <input
                type="date"
                class="inline-block border-2 px-2 rounded-sm mr-2"
                name=""
                id=""
                v-model="dateData.end_date"
              />
              <input
                type="time"
                class="inline-block border-2 px-2 rounded-sm mr-2"
                name=""
                id=""
                v-model="dateData.endTime"
              />
            </div>
            <div class="flex mt-3">
              <button
                @click="fetchRecords"
                class="block mr-2 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
              >
                Get Records
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row mt-5">
        <table class="shadow-lg bg-white w-full">
          <thead>
            <th
              class="text-l p-3 text-left"
              colspan="7"
              style="background: #DEE6F2;"
            >
              Merchant Information
            </th>
            <tr style="background: #fff;">
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Business ID
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Business Name
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Email Address
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Amount
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Transaction Type
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Payment Reference
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Transaction Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(account, i) in filterDocuments" :key="i">
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.business_id }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.business_name }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.email_address }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.amount }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.transaction_type }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.payment_reference }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.transaction_status }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import { json2excel } from "js2excel";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      moment,
      isLoading: false,
      dateData: null,
      tim: new Date().getTime(),
      filterBy: [],
      search: "",
      walletFilter: [],
      downloadLink: null,
      loading: false,
      viewModal: false,
      payload: [],
      fundsData: [],
    };
  },
  created() {
    const d = new Date();

    this.dateData = {
      // start_date: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      start_date: new Date(d.setDate(d.getDate() - 1)).toJSON().split("T")[0],
      end_date: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
      // enddatetime: new Date().toJSON().split("T")[0]
    };

    this.payload = {
      start_date: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      end_date: new Date().toJSON().split("T")[0],
    };

    this.fetchRecords();
  },
  computed: {
    ...mapGetters(["GET_FUND_REVERSAL"]),
    filterDocuments() {
      //let y = this.GET_DOCUMENTS.data.filter((b) => b.status === x);
      if (this.fundsData.items) {
        return this.fundsData.items.filter(
          (document) =>
            document.payment_reference
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            document.amount.toLowerCase().includes(this.search.toLowerCase()) ||
            document.email_address
              .toLowerCase()
              .includes(this.search.toLowerCase())
        );
      }
    },
  },
  methods: {
    async downloadCSV() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch(
          "DOWNLOAD_CSV_SINGLE_FUND_TRANSACTIONS",
          {
            id: this.$route.params.id,
          }
        );
        if (res.status == true) {
          window.open(res.data, "_blank");
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
          $router.go(-1);
        }
      } catch (error) {}

      this.isLoading = false;
    },
    async fetchRecords() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("FETCH_SINGLE_FUND_TRANSACTIONS", {
          search: this.$route.params.id,
        });
        if (res.status == true) {
          this.fundsData = res.data;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
          $router.go(-1);
        }
      } catch (error) {}

      this.isLoading = false;

      //const res = this.$store.getters["GET_PAYMENT_LOGS_RES_CSV"];
    },

    // async reverseTransaction(transaction_referrence) {
    //   let res = confirm("Are you sure you want to reverse this transaction?");
    //   if (res === true) {
    //     this.isLoading = true;
    //     let data = { TransactionReference: transaction_referrence };
    //     try {
    //       let res = await this.$store.dispatch("REVERSE_FUNDS_TRANSFER", data);
    //       if (res.status == true) {
    //         alert("Reverse was successful");
    //         this.$router.go();
    //       } else {
    //         alert(
    //           res.error ||
    //             res.message ||
    //             "Error: Please check network or contact admin."
    //         );
    //       }
    //     } catch (error) {}

    //     this.isLoading = false;
    //   }
    // },
  },
};
</script>
<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
